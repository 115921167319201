<template>
  <CommonError
    errorMessage="You can not access this page!"
    :fontAwesomeIcon="['far', 'frown']"
  />
</template>

<script>
import CommonError from "@/components/common/CommonError.vue";
export default {
  name: "Page401",
  components:{CommonError}
};
</script>
<style></style>
